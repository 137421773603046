@import url(./normalize.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./fonts.css);
@import url(./datepicker.css);
@import url(./range-slider.css);
@import url(./menu.css);

@import "@fortawesome/fontawesome-free/css/all.css";
@import "fork-awesome";

.templaterenderer {
    max-height: 30rem;
}

.templaterenderer svg {
    max-height: 25rem;
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.dot {
    @apply bg-surface-pressed shadow-none !important
}

.dot.selected {
    @apply bg-accent !important
}

.video-cover {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-christmas {
    --color1: #ff5474;
    --color2: #46b08c;
    background: repeating-linear-gradient(45deg,
            var(--color1),
            var(--color1) 20px,
            var(--color2) 20px,
            var(--color2) 40px);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    animation: 40s linear 0s infinite move;
}
.text-ostern {
    --color1: #ffd1ba;
    --color2: #b5ead7;
    background: repeating-linear-gradient(45deg,
            var(--color1),
            var(--color1) 20px,
            var(--color2) 20px,
            var(--color2) 40px);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    animation: 40s linear 0s infinite move;
}

@keyframes move {
    from {
        background-position: 0px;
    }

    to {
        background-position: 1000px;
    }
}

.aspect-paper-portrait {
    aspect-ratio: 1 / 1.41421;
}

.aspect-paper-landscape {
    aspect-ratio: 1.41421 / 1;
}

.cell-error {
    @apply border-red-400 !important
}

.row-selected {
    @apply bg-surface !important
}

.row-unselected {}

.MuiAutocomplete-popper {
    @apply min-w-min
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}